const state = {
  user: null
};

const getters = {
  isAuthenticated: state => !!state.user
};

const mutations = {
  setUser(state, payload) {
    state.user = payload;
  }
};

const actions = {
  async signIn() {

  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};