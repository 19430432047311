<template>
  <div class="main-layout">
    <navigation v-if="isAuthenticated"></navigation>
    <main>
      <topbar v-if="isAuthenticated"></topbar>
      <div class="page-content">
        <slot></slot>
      </div>
    </main>
  </div>
</template>

<script>
  import Navigation from "@/components/core/Navigation.vue";
  import Topbar from "@/components/core/Topbar.vue";
  import { mapGetters } from "vuex";
  export default {
    name: "MainLayout",

    components: {
      Navigation,
      Topbar
    },

    computed: {
      ...mapGetters("auth", ["isAuthenticated"])
    }
  };
</script>