import "bootstrap/scss/bootstrap.scss";
import "@/assets/scss/index.scss";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { auth } from "@/clients/firebase";
import VueSwal from "vue-swal";

Vue.config.productionTip = false;
Vue.use(VueSwal);

let app;

auth.onAuthStateChanged(async user => {
  store.commit("auth/setUser", user);

  if (!app)
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount("#app");
});
