import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/sign-in",
    name: "sign-in",
    component: () => import("@/views/SignInView.vue")
  },
  {
    path: "/",
    redirect: "/dashboard"
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("@/views/DashboardView.vue"),
    meta: {
      authRequired: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const authRequired = to.matched.some(record => record.meta.authRequired);
  const isAuthenticated = !!store.getters["auth/isAuthenticated"];

  if (authRequired && !isAuthenticated) return next("/sign-in");
  else if (!authRequired && isAuthenticated) return next("/dashboard");
  else next();
});

export default router;
