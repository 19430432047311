import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

firebase.initializeApp({
  apiKey           : process.env.VUE_APP_APIKEY,
  authDomain       : process.env.VUE_APP_AUTHDOMAIN,
  databaseURL      : process.env.VUE_APP_DATABASEURL,
  projectId        : process.env.VUE_APP_PROJECTId,
  storageBucket    : process.env.VUE_APP_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
  appId            : process.env.VUE_APP_APPId,
  measurementId    : process.env.VUE_APP_MEASUREMENTID
});

export default firebase;
export const auth = firebase.auth();
export const firestore = firebase.firestore();